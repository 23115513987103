import { theme } from "../definitions/theme";

import { AboutMe } from "./home/AboutMe";
import { Portfolio } from "./home/Portfolio";
import { Slogan } from "./home/Slogan";
import { Vita } from "./home/Vita";

export function Home() {
  return (
    <>
      <Slogan minHeight={100} backgroundColor={theme.palette.primary.dark} />
      <Portfolio
        minHeight={500}
        backgroundColor={theme.palette.secondary.light}
      />{" "}
      <AboutMe minHeight={500} backgroundColor={theme.palette.secondary.main} />
      <Vita minHeight={500} backgroundColor={theme.palette.secondary.light} />
    </>
  );
}
