import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import type { Theme } from "@mui/material/styles";

const font = `Akatab`;
const fontUrl = `https://fonts.googleapis.com/css2?family=${font}:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"`;

export const typoGraphySx = {
  paddingLeft: (curTheme: Theme) => curTheme.spacing(3),
  paddingRight: (curTheme: Theme) => curTheme.spacing(3),
  marginTop: (curTheme: Theme) => curTheme.spacing(2),
  marginBottom: (curTheme: Theme) => curTheme.spacing(3),
};

// eslint-disable-next-line no-lone-blocks
{
  /*    primary: {
      main: "#7C7C27",
      dark: "#222204",
      light: "#fefee5",
    },
    secondary: {
      main: "#c9c9bb",
      dark: "#64644D",
      light: "#fffcf7",
      #FFB502

orange: ff7200
blue: 2779a7
    primary: {
      main: "#4C4C4C", // menu
      dark: "#2779a7", // slogan
      light: "#4C4C4C", // footer
    },
    secondary: {
      main: "#A9A9A9", // about me
      // dark: "#2779a7",
      light: "#E5E4E2", // vita, portfolio
    },
    }, */
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4C4C4C", // menu
      dark: "#2E8B57", // slogan
      light: "#4C4C4C", // footer
    },
    secondary: {
      main: "#E5E4E2", // about me
      // dark: "#2779a7",
      light: "#FAF9F6", // vita, portfolio
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: font,
    fontWeightRegular: 400,
    fontSize: 18,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {},
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: font,
          src: fontUrl,
        },
        body: {
          fontSize: "3rem",
        },
      },
    },
  },
});
