import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { StyledBox } from "../../components/StyledBox";
import { typoGraphySx } from "../../definitions/theme";

import type { PageProperties } from "../../types/PageProperties";
import type { Theme } from "@mui/material/styles";

export function Portfolio(props: PageProperties) {
  const { backgroundColor, minHeight } = props;

  return (
    <>
      <Grid
        id="portfolio"
        container
        item
        xs={12}
        sx={{
          minHeight,
          backgroundColor,
        }}
      >
        <StyledBox
          sx={{
            paddingTop: (curTheme: Theme) => curTheme.spacing(10),
            // paddingBottom: (curTheme: Theme) => curTheme.spacing(1),
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ paddingBottom: (curTheme: Theme) => curTheme.spacing(5) }}
          >
            <Grid item md={4} xs={12}>
              <Typography component={"h2"} variant={"h5"}>
                Professionalisierung
              </Typography>
              <Typography sx={typoGraphySx}>
                Ich helfe Führungskräften sich und die eigene Arbeit zu
                professionalisieren. Mein Fokus liegt dabei auf der Entwicklung
                des persönlichen Führungsstils, Kommunikation und dem Umgang mit
                Konflikten. Ich unterstütze die Reflektion der eigenen
                Führungsarbeit durch Coaching, Beratung und der gemeinsamen
                Ableitung konkreter Handlungsschritte und Ziele. Ich
                unterstütze, wenn es auf eine gute Situations-Analyse ankommt,
                ohne zu banalisieren und fokussiere dabei die Befähigung und den
                wertschätzenden Umgang mit allen Mitarbeitenden.
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography component={"h2"} variant={"h5"}>
                Teamentwicklung
              </Typography>
              <Typography sx={typoGraphySx}>
                Teams, die regelmäßig ihre Zusammenarbeit reflektieren und
                bewusst ihre soziale und wirtschaftliche Performanz als Team
                entwickeln und fördern, haben nachweislich größeren Erfolg. Sie
                helfen dem Unternehmen wirtschaftlich resilient und erfolgreich
                zu werden und zu bleiben. Ich begleite Teams in Zeiten von
                Umbrüchen und Konflikten. Und in Zeiten in denen es wichtig ist,
                dass ein Team stabil ist und bleibt.
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography component={"h2"} variant={"h5"}>
                Organisationen und Kultur
              </Typography>
              <Typography sx={typoGraphySx}>
                Mitarbeiter, die Verantwortung übernehmen sind sowohl befähigt,
                als auch authorisiert. Ich helfe dabei eine Kultur zu schaffen,
                in der Mitarbeitende Verantwortung übernehmen und mitdenken
                wollen, und dabei sich auch persönlich mit ihren Aufgaben
                entwickeln. Gemeinsam entwickeln wir anhand Wertströme und
                Kunden des Unternehmens, konkrete Schritte und Maßnahmen hin zu
                einer kooperativen, kollaborativen Unternehmenskultur, um so das
                Wissen und die Fähigkeiten der der gesamten Organisation zu
                nutzen. Ich helfe zu analysieren, besprechbar zu machen und
                Entscheidungen zur schrittweisen Optimierung gemeinsam zu
                treffen und kontrolliert umzusetzen.
              </Typography>
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
    </>
  );
}
