import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { typoGraphySx } from "../../definitions/theme";

import type { PageProperties } from "../../types/PageProperties";
import type { Theme } from "@mui/material/styles";

export function Vita(props: PageProperties) {
  const { backgroundColor /* , minHeight  */ } = props;

  return (
    <Grid
      id="vita"
      container
      item
      sx={{
        backgroundColor,
      }}
    >
      <Grid
        container
        item
        xs={12}
        sx={{ justifyContent: "center", alignContent: "center" }}
      >
        <Typography
          component={"h2"}
          variant={"h4"}
          gutterBottom
          sx={{ paddingTop: (curTheme: Theme) => curTheme.spacing(5) }}
        >
          Ressourcen
        </Typography>
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingBottom: (curTheme: Theme) => curTheme.spacing(10),
          }}
        >
          <Typography
            component={"h3"}
            variant={"h5"}
            gutterBottom
            sx={{
              textAlign: "center",
            }}
          >
            Ausbildung & Erfahrung
          </Typography>
          <Typography sx={typoGraphySx}>
            Zehnjährige Tätigkeit als Unternehmensberater mit dem Schwerpunkt
            &quot;Agile Enterprise Transformation&quot;, zuletzt als
            Intrapreneur über sechs Jahre für Accenture als Senior Manager und
            Senior Agile Advisor.
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://de.wikipedia.org/wiki/Systemische_Transaktionsanalyse"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Systemische Transaktion&shy;sanalyse
            </Link>{" "}
            bei Kessel und Kessel (Bertine Kessel)
          </Typography>
          <Typography sx={typoGraphySx}>
            Studium der Informatik und Philosophie an der Uni Bremen und der
            Fernuni Hagen (Abschluss als Dipl.-Inf.)
          </Typography>
          <Typography sx={typoGraphySx}>
            Sieben Jahre in der Kinder- und Jugendhilfe mit schwer erziehbaren
            Kindern und Jugendlichen
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{}}>
          <Typography
            component={"h3"}
            variant={"h5"}
            gutterBottom
            sx={{
              textAlign: "center",
            }}
          >
            Zertifizierung & Auszeichnung
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://management30.com/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Management 3.0 Facilitator
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://kanban.university/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Accredited Kanban Trainer (AKT)
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://leanchange.org/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Lean Change Agent & Professional
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            <Link
              to="https://scaledagileframework.com/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Scaled Agile Framework Program Consultant (SPC6)
            </Link>
          </Typography>

          <Typography sx={typoGraphySx}>
            <Link
              to="https://www.icagile.com/certification/agile-coaching"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              {" "}
              ICAgile Certified Professional Business Agility Foundations
              (ICP-BAF)
            </Link>{" "}
            &{" "}
            <Link
              to="https://www.icagile.com/certification/business-agility-foundations"
              target="_blank"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              ICAgile Certified Professional Business Agility Foundations
              (ICP-BAF)
            </Link>
          </Typography>
          <Typography sx={typoGraphySx}>
            Accenture Leadership Awards 2016 (Mitarbeiterwahl)
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
