import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { typoGraphySx } from "../../definitions/theme";

import type { PageProperties } from "../../types/PageProperties";
import type { Theme } from "@mui/material/styles";

export function AboutMe(props: PageProperties) {
  const { backgroundColor } = props;

  return (
    <Grid
      id="vita"
      container
      item
      sx={{
        backgroundColor,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sm={5}
        order={{ xs: 2, sm: 2 }}
        sx={{
          paddingTop: (curTheme: Theme) => curTheme.spacing(5),
          paddingBottom: (curTheme: Theme) => curTheme.spacing(5),
        }}
      >
        <Typography sx={typoGraphySx}>
          Ausbildung in Kommunikationsanalyse und anschlussfähiger Kommunikation
        </Typography>
        <Typography sx={typoGraphySx}>
          Berater und Trainer für Aufbau und Führung von agilen Unternehmen und
          Organisationen
        </Typography>
        <Typography sx={typoGraphySx}>
          Prozessoptimierung durch Nutzung des Wissens der gesamten Organisation
        </Typography>
        <Typography sx={typoGraphySx}>
          Adaptives, agiles Change Management für Organisationen und Kultur
        </Typography>
        <Typography sx={typoGraphySx}>
          Entwicklung skalierter agiler Organisationen
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        order={{ xs: 1, sm: 1 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: (curTheme: Theme) => curTheme.spacing(5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: { xs: "15rem", md: "20rem" },
            }}
            alt="Dominik Elsbroek"
            src={"/dominikelsbroek.webp"}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
