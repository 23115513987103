import Box from "@mui/material/Box";

import type { SxProps, Theme } from "@mui/material/styles";

export type BoxProps = {
  id?: string;
  backgroundColor?: string;
  minHeight?: string | number;
  children?: React.ReactNode;
  justifyItems?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  alignContent?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  sx?: SxProps<Theme>;
};

export function StyledBox(props: BoxProps) {
  const {
    id,
    backgroundColor,
    minHeight,
    sx,
    justifyItems,
    alignContent,
    textAlign,
  } = props;
  return (
    <>
      <Box
        id={id}
        sx={{
          backgroundColor,
          minHeight,
          justifyItems: justifyItems || "center",
          alignContent: alignContent || "center",
          textAlign: textAlign || "center",
          ...sx,
        }}
      >
        {props.children}
      </Box>
    </>
  );
}
