import Grid from "@mui/material/Grid";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home";

const Debug = lazy(() =>
  import("./pages/Debug").then((module) => ({ default: module.Debug })),
);
const Agb = lazy(() =>
  import("./pages/Agb").then((module) => ({ default: module.Agb })),
);
const Test = lazy(() =>
  import("./pages/Test").then((module) => ({ default: module.Test })),
);
const Imprint = lazy(() =>
  import("./pages/Imprint").then((module) => ({ default: module.Imprint })),
);

export function App() {
  return (
    <>
      <NavBar />
      <Grid container sx={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/agb" element={<Agb />} />
          <Route path="/debug" element={<Debug />} />
          <Route path="/test" element={<Test />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Grid>
    </>
  );
}
