import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { StyledBox } from "../../components/StyledBox";

import type { PageProperties } from "../../types/PageProperties";

export function Slogan(props: PageProperties) {
  const { backgroundColor, minHeight } = props;
  return (
    <>
      <Grid item xs={12}>
        <StyledBox minHeight={minHeight} backgroundColor={backgroundColor}>
          <Typography
            sx={{
              color: "white",
              fontSize: 30,
              marginRight: (curTheme) => curTheme.spacing(5),
              marginLeft: (curTheme) => curTheme.spacing(5),
              marginTop: (curTheme) => curTheme.spacing(5),
            }}
          >
            „Von einer gewissen Position in Wirtschaft und Gesellschaft an
            <br />
            ist die Persönlich&shy;keits&shy;bildung die wichtigste Vorbereitung
            <br />
            für gekonnte Dialektik.“
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 14,
              textAlign: "center",
              // marginLeft: (curTheme) => curTheme.spacing(60),
              marginBottom: (curTheme) => curTheme.spacing(3),
            }}
          >
            - Rupert Lay
          </Typography>
        </StyledBox>
      </Grid>
    </>
  );
}
